<template>
    <div :class="{'sidebar-lang': this.$parent.name === 'Sidebar', 'language-selector' : true}">
        <Select
            ref="select"
            name="singleSelect"
            :label="label"
            :value="selectedValue"
            :allOptions="allLanguages"
            @change="changeLanguage($event)"
        />
    </div>
</template>

<script>
import Select from './Select.vue'
import { setCookie, getCookie } from './../../../utils/cookieUtils'
import EventBus from '@/app/shared/event-bus.js'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'LanguageSelector',
  components: {
    Select
  },
  props: {
    label: {
      Type: String,
      default: 'Language'
    }
  },
  computed: {
    ...mapState('Sidebar', ['allowedLanguages']),
    selectedValue: {
      get: function () {
        let localSelectedLanguage = getCookie('languageCode')
        if (localSelectedLanguage === null) localSelectedLanguage = 'en' // set English as default language
        return this.allLanguages.filter(option => option.Key === localSelectedLanguage)[0]
      },
      set: function (value) {
        /* istanbul ignore next */
        return value
      }
    },
    allLanguages () {
      const languages = []
      /* istanbul ignore next */
      for (const lang in this.allowedLanguages) {
        const obj = { Key: lang, Value: this.allowedLanguages[lang] }
        languages.push(obj)
      }
      return (languages)
    }
  },
  methods: {
    /* istanbul ignore next */
    ...mapActions('Sidebar', ['GetAllowedLanguagesForDeployment']),
    changeLanguage (language) {
      setCookie('languageCode', language.Key, 60)
      this.$i18n.locale = language.Key
      EventBus.$emit('LANGUAGE_CHANGE')
    },
    async getLanguages () {
      await this.GetAllowedLanguagesForDeployment()
    }
  },
  async mounted () {
    await this.getLanguages()
    if (getCookie('languageCode') == null) {
      setCookie('languageCode', 'en', 60)
      this.$i18n.locale = 'en'
    }
  }
}
</script>

<style lang='scss' scoped>
.sidebar-lang {
  margin: 16px 10px 16px 10px;
}
</style>
