<template>
  <div class="sidebar-list">
    <language-selector v-if="showLanguageSelector" ref="lang" label=""></language-selector>
    <div v-for="(item, index) in list" :key="index">
      <div
        :class="{
          'sidebar-item': true,
          'selected': selectedItemName === item.name
        }"
        @click="selectMenuItem(item)"
      >
        <div class="sidebar-menu">
          <icon-base
          width="18"
          height="18"
          :iconName="item.icon"
          :fill="selectedItemName === item.name ? 'white' : '#9CA6BA'"></icon-base>
          <span :id=getItemId(item.name) class="sidebar-name" v-if="!collapsed"> {{ $t(item.name) }} </span>
        </div>
        <div v-if="item.children && !collapsed" class="open-children-arrow">
          <icon-base width="14"
          height="14" iconName="angle-down"></icon-base>
        </div>
      </div>
      <div v-if="item.children && item.showChildren">
        <sidebar :list="item.children" :collapsed=collapsed></sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from './IconBase.vue'
import LanguageSelector from './LanguageSelector'
import { mapActions } from 'vuex'

export default {
  name: 'Sidebar',
  props: {
    list: Array,
    selectedMenuItemName: String,
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconBase,
    LanguageSelector
  },
  methods: {
    ...mapActions('Reports', ['setActiveReport']),
    hideAllChildren () {
      this.list.map(item => {
        item.showChildren = false
        return item
      })
    },
    toggleShowChildren (itemName) {
      const item = this.list.filter(item => item.name === itemName)[0]
      item.showChildren = !item.showChildren
    },
    selectMenuItem (item) {
      const itemName = item.name
      const hasChildren = item.children

      if (hasChildren) {
        this.toggleShowChildren(itemName)
        if (this.collapsed) {
          this.$emit('toggleCollapseState')
        }
      } else {
        if (item.relativePath) {
          this.$router.push(item.link)
          if (itemName === 'reports') {
            this.setActiveReport(null)
          }
        } else {
          window.open(item.link, '_self')
        }
      }
      if (this.selectedItemName !== itemName) {
        if (this.selectedItemName && !this.collapsed) {
          this.toggleShowChildren(this.selectedItemName)
        }
        this.selectedItemName = itemName
      }
    },
    getItemId (item) {
      const itemName = this.$t(item)
      return itemName.replace(/ /g, '')
    }
  },
  computed: {
    showLanguageSelector () {
      return (!this.$parent.$data.collapsed)
    }
  },
  data () {
    return {
      name: 'Sidebar',
      showChildren: false,
      selectedItemName: this.selectedMenuItemName
    }
  },
  mounted () {
    const pathArray = window.location.pathname.split('/')
    const link = '/' + pathArray[1] + '/' + pathArray[2]
    this.selectedItemName = this.list.filter(item => item.link === link)[0].name
  }
}
</script>

<style lang="scss" scoped>
.sidebar-list {
  background: var(--sidebar-bg);
  color: $white;
  //min-width: 239px;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: relative;
  top: 0;
  white-space: nowrap;
  overflow-y: auto;
}

.sidebar-item {
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 12px 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-left: 2px solid transparent;
}
.sidebar-item:hover {
  background-color: var(--sidebar-hover);
  transition: 0.3s;
}

.sidebar-menu {
  display: flex;
  align-items: center;
}

.sidebar-name {
  padding-left: 18px;
}

.sidebar-logo {
  width: 18px;
  height: 18px;
}

.open-children-arrow {
  position: relative;
  top:3px;
  margin-left: auto;
  right: -14px;
  margin-right: 12px;
}

.selected {
  // border: solid $sidebarSelectedItemBorder ;
  // border-width: 0 0 0 5px;
  border-left: 3px solid $secondary;
  background: var(--sidebar-hover);
}
.selected .open-children-arrow svg {
  transform: rotate(
    180deg
  );
  transition: 0.3s;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: $line;
}

</style>
