<template>
    <div class="sidebar">
        <div class="collapse-button" @click="toggleCollapseStateParent()">
            <div :class="{'collapse-arrow': true, 'collapsed': this.collapsed}">
                <font-awesome-icon icon="chevron-left" size="xs" class="dark-text"/>
            </div>
        </div>
        <sidebar ref="sidebar" :list="list" :collapsed=collapsed @toggleCollapseState="toggleCollapseStateChild"></sidebar>
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
export default {
  name: 'CollapsableSidebar',
  props: {
    list: Array,
    selectedMenuItemName: String
  },
  components: {
    Sidebar
  },
  methods: {
    toggleCollapseStateParent () {
      this.collapsed = !this.collapsed
      if (this.collapsed) {
        this.$refs.sidebar.hideAllChildren()
      } else if (this.$refs.sidebar.selectedItemName) {
        this.$refs.sidebar.toggleShowChildren(this.$refs.sidebar.selectedItemName)
      }
    },
    toggleCollapseStateChild () {
      this.collapsed = !this.collapsed
      if (this.collapsed) {
        this.$refs.sidebar.hideAllChildren()
      }
    }
  },
  data () {
    return {
      collapsed: false
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  background: var(--sidebar-bg);
  color: $white;
  // min-width: 239px;
  display: flex;
  flex-direction: column;
  position: -webkit-sticky; /* Safari */
  position: relative;
  top: 0;
  white-space: nowrap;
  box-shadow: 1px 0px 1px var(--very-light-shadow);

}

.collapse-button {
  z-index: 100;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 10px;
  background: var(--background-color-primary);
  border-radius: 100%;
  box-shadow: 1px 4px 10px var(--very-light-shadow);
  left: calc(100% - 15px);
}

.collapse-arrow {
  z-index: 100;
  position: absolute;
  left: 38%;
  top: 10%;
  /* transform: matrix(0, -1, -1, 0, 0, 0); */
}

.collapsed {
  transform: rotate(
    180deg
  );
  transition: 0.3s;
  left: 43%;
  top: 18%;
}
</style>
